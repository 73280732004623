export const getContainerStyles = ({ pt, pb }) => ({
  paddingTop: pt,
  paddingBottom: pb,
  paddingX: '22px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#f1f0f0',
  '.dialogue-speaker': {
    flexShrink: 0,
    fontSize: ['16px', null, '32px'],
    lineHeight: ['24px', null, '42px'],
    fontWeight: 700,
  },
  '.dialogue-quote': {
    marginLeft: ['0px', null, null, null, '10px'],
    fontSize: ['16px', '22px', '28px', '32px'],
    lineHeight: ['24px', '26px', '32px', '42px'],
    fontWeight: 400,
  },
  '.dialouge-footnote': {
    paddingTop: '20px',
    fontSize: ['16px', null, '22px'],
    fontWeight: 400,
  },
});
