import React from 'react';

import { Flex, Box, Themed, Text } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';

import { getContainerStyles } from './styles';

import { DialogueProps } from './types';

const DialogueTemplate = ({
  title,
  dialogues,
  footnote,
  topMargin,
  bottomMargin,
}: DialogueProps) => {
  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Container containerSx={getContainerStyles({ pt, pb })} fullWidth>
      {title && (
        <Flex
          sx={{
            flexDirection: 'column',
            mx: [0, 2, 3, 6],
            mb: [4, 5, 5],
            textTransform: 'uppercase',
            alignItems: 'center',
          }}
        >
          <Themed.h2 as="h2">{title}</Themed.h2>
        </Flex>
      )}
      <Box className="dialogues-container">
        {dialogues.map(dialogue => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', null, null, null, 'row'],
              marginBottom: '10px',
            }}
          >
            <Text className="dialogue-speaker">{`${dialogue.speaker}: `}</Text>
            <Text className="dialogue-quote">{dialogue.quote.quote}</Text>
          </Box>
        ))}
        {footnote && (
          <Text as="p" className="dialouge-footnote">
            {footnote}
          </Text>
        )}
      </Box>
    </Container>
  );
};

export default DialogueTemplate;
